import { PATH_POINTERS } from '@/constants/builder';
import * as serializers from '@/constants/serializer';

import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

const linkedProperties = {
  PROJECT: 'project',
  BUILDING: 'building',
  INVENTORY: 'inventory',
};

/** @type {import('@/schemas/schema').default} */
export const detailsSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'unit.unit_name',
        prop: 'header',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        label: 'unit.project_name',
        prop: linkedProperties.PROJECT,
        type: schemaItemTypes.PROJECT,
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        label: 'unit.building_name',
        prop: linkedProperties.BUILDING,
        type: schemaItemTypes.BUILDING,
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        label: 'unit.type',
        prop: 'propertyType',
        type: schemaItemTypes.ENUM,
        rules: [rules.REQUIRED],
        dictionary: dictionaryTypes.PROPERTY_TYPES,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'unit.rent_amount',
        prop: 'goalRentCost',
        type: schemaItemTypes.NUMBER,
        rules: [rules.REQUIRED, rules.POSITIVE],
        size: 3,
      },
    ],
  },
  {
    label: 'unit.status',
    type: schemaItemTypes.TITLED_SECTION_WITH_STATUS,
    payload: [
      {
        param: 'status',
        from: ['status'],
        serializer: serializers.CAPITALIZE_FIRST_LETTER,
      },
    ],
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.CONTRACTS,
            size: 8,
            isUnit: true,
            payload: [
              {
                param: 'usageId',
                from: ['id'],
              },
              {
                param: 'roomsUsages',
                from: ['roomsUsages'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'unit.details',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        fill: true,
        children: [
          {
            label: 'unit.number_of_bedrooms',
            prop: 'numberOfBedrooms',
            type: schemaItemTypes.NUMBER,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'unit.number_of_baths',
            prop: 'numberOfBaths',
            type: schemaItemTypes.NUMBER,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        fill: true,
        children: [
          {
            label: 'unit.parking_type',
            prop: 'parking',
            type: schemaItemTypes.ENUM,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'unit.parking_number',
            prop: 'parkingNumber',
            type: schemaItemTypes.STRING,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        fill: true,
        children: [
          {
            label: 'unit.roof_garden',
            prop: 'isRoofGarden',
            type: schemaItemTypes.BOOLEAN,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'unit.swimming_pool',
            prop: 'isSwimmingPool',
            type: schemaItemTypes.BOOLEAN,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'unit.internet',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        fill: true,
        children: [
          {
            label: 'unit.internet_login',
            prop: 'internetLogin',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'unit.internet_password',
            prop: 'internetPassword',
            type: schemaItemTypes.STRING,
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'unit.photos',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        prop: 'photos',
        type: schemaItemTypes.IMAGE_LIST,
      },
    ],
  },
  {
    label: 'unit.inventory',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        prop: linkedProperties.INVENTORY,
        type: schemaItemTypes.LIST,
        uniqueKey: 'id',
        row: true,
        min: 0,
        max: 10,
        children: [
          {
            type: schemaItemTypes.ROW,
            children: [
              {
                label: 'unit.title',
                prop: 'title',
                type: schemaItemTypes.STRING,
                rules: [rules.REQUIRED],
                size: {
                  md: 6,
                  xl: 4,
                },
              },
              {
                label: 'unit.quantity',
                prop: 'quantity',
                type: schemaItemTypes.NUMBER,
                rules: [rules.REQUIRED],
                size: 6,
              },
            ],
          },
          {
            label: 'unit.photos',
            prop: 'images',
            type: schemaItemTypes.IMAGE_LIST,
            rules: [rules.REQUIRED],
            payload: [
              {
                param: 'listTitle',
                from: [linkedProperties.INVENTORY, PATH_POINTERS.currentIndex, 'title'],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const addressSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'unit.unit_address',
        prop: 'address',
        type: schemaItemTypes.STRING,
        size: 3,
      },
      {
        label: 'unit.floor',
        prop: 'floor',
        type: schemaItemTypes.NUMBER,
        size: 3,
      },
      {
        label: 'unit.apartment_number',
        prop: 'number',
        type: schemaItemTypes.STRING,
        size: 3,
      },
    ],
  },
  {
    label: 'unit.subway_station',
    prop: 'subwayStation',
    type: schemaItemTypes.STRING,
    size: 3,
  },
];

export const areasSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.ROW,
        size: 9,
        children: [
          {
            label: 'unit.internal_area',
            prop: 'internalArea',
            type: schemaItemTypes.SQUARE,
            rules: [rules.REQUIRED, rules.POSITIVE],
            size: 4,
            payload: [
              {
                param: 'areaUnit',
                from: [linkedProperties.PROJECT, 'areaUnit'],
              },
            ],
          },
          {
            label: 'unit.total_buildable_area',
            prop: 'totalBuildableArea',
            type: schemaItemTypes.SQUARE,
            rules: [rules.REQUIRED, rules.POSITIVE],
            size: 4,
            payload: [
              {
                param: 'areaUnit',
                from: [linkedProperties.PROJECT, 'areaUnit'],
              },
            ],
          },
          {
            label: 'unit.covered_verandas',
            prop: 'coveredVerandas',
            type: schemaItemTypes.SQUARE,
            rules: [rules.POSITIVE],
            size: 4,
            payload: [
              {
                param: 'areaUnit',
                from: [linkedProperties.PROJECT, 'areaUnit'],
              },
            ],
          },
          {
            label: 'unit.uncovered_verandas',
            prop: 'uncoveredVerandas',
            type: schemaItemTypes.SQUARE,
            rules: [rules.POSITIVE],
            size: 4,
            payload: [
              {
                param: 'areaUnit',
                from: [linkedProperties.PROJECT, 'areaUnit'],
              },
            ],
          },
          {
            label: 'unit.lower_level',
            prop: 'lowerLevel',
            type: schemaItemTypes.SQUARE,
            rules: [rules.POSITIVE],
            size: 4,
            payload: [
              {
                param: 'areaUnit',
                from: [linkedProperties.PROJECT, 'areaUnit'],
              },
            ],
          },
          {
            label: 'unit.storage',
            prop: 'storage',
            type: schemaItemTypes.SQUARE,
            rules: [rules.POSITIVE],
            size: 4,
            payload: [
              {
                param: 'areaUnit',
                from: [linkedProperties.PROJECT, 'areaUnit'],
              },
            ],
          },
          {
            label: 'unit.plot',
            prop: 'plot',
            type: schemaItemTypes.SQUARE,
            rules: [rules.POSITIVE],
            size: 4,
            payload: [
              {
                param: 'areaUnit',
                from: [linkedProperties.PROJECT, 'areaUnit'],
              },
            ],
          },
        ],
      },
      {
        size: 3,
      },
    ],
  },
];

export const landlordInfoSchema = [
  {
    label: 'landlord.personal_information',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'landlord.name',
            prop: 'clientName',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'landlord.id',
            prop: 'clientId',
            type: schemaItemTypes.STRING,
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'landlord.contract',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'landlord.contract_id',
            prop: 'contractId',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'landlord.contract_title',
            prop: 'contractTitle',
            type: schemaItemTypes.STRING,
            size: 3,
          },
          {
            label: 'landlord.contract_work_model',
            prop: 'contractWorkModel',
            type: schemaItemTypes.STRING,
            size: 3,
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'landlord.contract_start_date',
            prop: 'contractStartDate',
            type: schemaItemTypes.DATE,
            size: 3,
          },
          {
            label: 'landlord.contract_end_date',
            prop: 'contractEndDate',
            type: schemaItemTypes.DATE,
            size: 3,
          },
          {
            label: 'landlord.contract_with_landlord',
            prop: 'contractWithClientScan',
            type: schemaItemTypes.IMAGE_LIST,
            editable: true,
            onlyOne: true,
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'landlord.property_managment_latter',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'landlord.pml_start_date',
            prop: 'contractPmlStartDate',
            type: schemaItemTypes.DATE,
            size: 3,
          },
          {
            label: 'landlord.pml_end_date',
            prop: 'contractPmlEndDate',
            type: schemaItemTypes.DATE,
            size: 3,
          },
          {
            label: 'landlord.property_managment_latter',
            prop: 'propertyManagementLetterScan',
            type: schemaItemTypes.IMAGE_LIST,
            editable: true,
            onlyOne: true,
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'landlord.documents',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'landlord.passport',
            prop: 'clientPassport',
            type: schemaItemTypes.IMAGE_LIST,
            editable: true,
            onlyOne: true,
            size: 3,
          },
          {
            label: 'landlord.dtsm_permit',
            prop: 'dtcmPermitScan',
            type: schemaItemTypes.IMAGE_LIST,
            editable: true,
            onlyOne: true,
            size: 3,
          },
          {
            label: 'landlord.property_title_deed',
            prop: 'propertyTitleDeedScan',
            type: schemaItemTypes.IMAGE_LIST,
            editable: true,
            onlyOne: true,
            size: 3,
          },
        ],
      },
    ],
  },
];
